import React from 'react';
import './App.css';

const App = () => {
  return (
    <div className="app-container">
      <div className="side-heart left-heart"></div>
      <div className="side-heart right-heart"></div>
      <h1 className="heading">My Cutie Jaan</h1>
      <div className="image-heart-container">
        <img 
          src={`${process.env.PUBLIC_URL}/mine.jpeg`} 
          alt="Beautiful" 
          className="picture"
        />
        <div className="heart"></div>
      </div>
      <div className="text-container">
        <p className="sorry-text">I'm so sorry, my love. I messed up alot. Please forgive me. 
        You're my everything and so important to me but I couldn't express it completely. 
        I will improve and change myself for you and will be more careful about your feelings. 
        Promise I will never hurt you again ever and never make you cry. 
        I love you so much and care about you alot my cutie.</p>
      </div>
    </div>
  );
};

export default App;


