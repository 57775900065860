import React from 'react';
import './birthday.css';
import Balloon from './Balloon';

const Birthday = () => {
  return (
    <div className="app-container">
      <div className="side-heart left-heart"></div>
      <div className="side-heart right-heart"></div>
      <h1 className="heading">Happy Birthday My Faiza</h1>
      <div className="image-heart-container">
      <Balloon />
      <Balloon />
        <video width="550" height="300" controls>
            <source src={`${process.env.PUBLIC_URL}/birth.mp4`}  type="video/mp4" />
            Your browser does not support the video tag.
        </video>
        <Balloon />
        <Balloon />
      </div>
      <div className="text-container">
        <p className="sorry-text">A very very Happy Birthday 🎂 my Faiza, my jaan 🥹🥺❤️🫂. Thank you bht ziada for being part of my life. But you are not just part, you are my life ❤️🫂❤️🫂. I love you so so much. You are so so amazing. You have my heart. I love everything about you, your nature, your smile, your hair, your eyes, your beautiful face, your dressing sense, I love you completely ❤️🫂🥹🥺. You are very important and special to me 🥹🥺❤️❤️. You are the queen 👑 of my heart. You rule my emotions. You are such an amazing person. May this year bring a lot of blessings and happiness for you. And we get together for the complete life and humesha stay happy🥺🥹❤️🫂🫂. Happy Birthday to you my soulmate my beautiful faizaa 🥹🫂❤️❤️🫂</p>
      </div>
    </div>
  );
};

export default Birthday;


