// src/Balloon.js
import React from 'react';
import './Balloon.css';

const Balloon = () => {
  return (
    <div className="balloon-container">
      <div className="balloon"></div>
      <div className="knot"></div>
      <div className="string"></div>
    </div>
  );
};

export default Balloon;
