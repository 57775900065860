// src/Balloon.js
import React from 'react';
import './RedBalloon.css';

const RedBalloon = () => {
  return (
    <div className="balloon-container-red">
      <div className="balloon-red"></div>
      <div className="knot-red"></div>
      <div className="string-red"></div>
    </div>
  );
};

export default RedBalloon;
