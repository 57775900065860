import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Day5birth from './5days';
import Birthday from './birthday';
import Anniversary from './anniversary';

const router = createBrowserRouter([
  {
    path: "/home",
    element: <App/>,
  },
  {
    path: '/birth',
    element: <Day5birth/>
  },
  {
    path: '/',
    element: <Birthday/>
  },
  {
    path: '/happyanniversary',
    element: <Anniversary/>
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
