import React from 'react';
import './anniversary.css';
import Balloon from './Balloon';
import RedBalloon from './RedBalloon';
import RosePetals from './RosePetals';

const Anniversary = () => {
  return (
    <>
    <RosePetals />
    <div className="app-container">
      <div className="side-heart left-heart"></div>
      <div className="side-heart right-heart"></div>
      <h1 className="heading">Happy 1st Anniversary My Love Faiza ♥️♥️</h1>
      <div className="scrollable-container">

      <div className="text-container">
        <p className="sorry-text">
        Happy 1st Anniversary my lovely faiza 🥹❤️. Aj hum dono ka 1 year aik sath complete hogaya hai 🫂🫂 Yeh aik saal bht ziada khubsoorat tha. You came into my life and I feel so lucky to have you with me 🥹❤️❤️ This day is very special to me and holds a great significane in my heart because it's the day when you said yes to my proposal 🥹🥺❤️ It's the day when the journey of our love for each other started. It's the day when we decided that we want to spend our lives together. It's the day I confessed my feelings for you. It's the day you made me feel luckiest person on this planet. It's the day when we decided to not give up on each other 🥺🥹❤️🫂🫂
This whole year we both faced alot of hardships as well. We fought for each other to be together. We became close to each other. If I look back to the 1 year we have spent, we made alot of beautiful memories, went through different phases, we fought for our future together 🫂🫂🥹❤️. Those difficult phases brought us even more closer to each other and made us realize that we are meant for each other, we are each other's soulmates and we love each other had sa ziada 🥹🥺❤️❤️🫂🫂 . I Love you so so much Faiza my Jaanemaan, Rasgulla, Cutie, Jaanejana, JaneBaharan, Haseena, Dilruba, Chaand 😘♥️💖🫂🥺 
        </p>
      </div>
      <div className="image-heart-container">
      <Balloon/>  
      <RedBalloon />
        <video width="350" height="300" controls>
            <source src={`${process.env.PUBLIC_URL}/anniversary-wish-2.mp4`}  type="video/mp4" />
            Your browser does not support the video tag.
        </video>
        <div className="image-container">
            <img
            src={`${process.env.PUBLIC_URL}/fpic.jpeg`}
            alt="Anniversary"
            className="anniversary-image"
            />
        </div>
        <video width="350" height="300" controls>
            <source src={`${process.env.PUBLIC_URL}/anniversary-wish.mp4`}  type="video/mp4" />
            Your browser does not support the video tag.
        </video>

        <Balloon />
        <RedBalloon/>
      </div>
      <div className="image-heart-container">
        <Balloon />
        <RedBalloon/>
        <RedBalloon/>
        <Balloon />
        <RedBalloon/>
        <Balloon />
        <Balloon />
        <RedBalloon/>
        <Balloon />
        <Balloon />
        <RedBalloon/>
        </div>
        <div className="text-container">
        <p className="center sorry-text">
            I love you bht ziada meri jaan. I want to spend the rest of my life with you 🥺♥️💖🥺🫂🥺 
        </p>
        </div>
        <div className="image-heart-container">
        <Balloon />
        <RedBalloon/>
        <Balloon />
        <Balloon />
        <RedBalloon/>
        <Balloon />
        <Balloon />
        <RedBalloon/>
        <Balloon />
        <RedBalloon/>
        <RedBalloon/>
        </div>
    </div>
    </div>
    </>
  );
};

export default Anniversary;


