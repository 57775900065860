import React, { useEffect } from "react";
import "./RosePetals.css";

const RosePetals = () => {
    useEffect(() => {
        const createFallingElement = () => {
          const element = document.createElement("div");
          const isHeart = Math.random() > 0.5; // Randomly choose heart or petal
          element.className = isHeart ? "falling heart" : "falling petal";
          element.style.left = `${Math.random() * 100}vw`;
          element.style.animationDuration = `${2 + Math.random() * 3}s`; // Random fall duration
          document.body.appendChild(element);
    
          setTimeout(() => {
            element.remove();
          }, 5000); // Remove element after falling
        };
    
        const interval = setInterval(createFallingElement, 300);
    
        return () => clearInterval(interval);
      }, []);
    
      return null; // No UI, just effects
};

export default RosePetals;
