import React from 'react';
import './5days.css';

const Day5birth = () => {
  return (
    <div className="app-container">
      <div className="side-heart left-heart"></div>
      <div className="side-heart right-heart"></div>
      <h1 className="heading">Happy Birthday In Advance</h1>

      <div className="text-container">
        <p className="sorry-text">5 Days Left since my cutie, my jaan, my noor-e-chasham was born.
        Happy Birthday in advance my dear faiza</p>
      </div>
    </div>
  );
};

export default Day5birth;